import React from 'react'
import { Link } from 'react-router-dom'
import './Cards.scss'
import front from './homepageData'

function Cards() {
  return front.map( (item) => (
      <Link className='box__link' key={item.id} to={`/ticket/${item.id}`}>
              <p className='box__para'>{item.txt}</p>
      </Link>      
  ))
}

export default Cards
