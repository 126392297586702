import React from 'react'
import './Contact.scss'

function Contact() {
  return (
    <div className='contact'>
      <div className='block__banner--contact'>
        <div className='block__shadow'></div>
      </div>
      <div className='wrapper'>
        <div className='contact__box'>
          <div className='contact__main'>
            <h2 className='contact__header'>company information</h2>
            <p className='contact__txt'>AMAROQ TRUCKING INC</p>
            <p className='contact__txt'>5318 E 2nd St #344 Long Beach, CA 90803</p>
            <p className='contact__txt'>Home Terminals: Chino, CA / Santa Fe Springs, CA</p>
            <p className='contact__txt'><span className='icon-phone'></span>Phone number: (562) 225 - 0126</p>
            <p className='contact__txt'><span className='icon-mail-alt'></span>Email: amaroqtrucking@gmail.com</p>
          </div>
        </div>
        <div className='contact__map'>
          <iframe className='contact__grid' title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3317.0692628744946!2d-118.13231189999999!3d33.758876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd303705c88af5%3A0xd0fbf444a3920605!2s5318%202nd%20St%20%23344%2C%20Long%20Beach%2C%20CA%2090803%2C%20USA!5e0!3m2!1sen!2srs!4v1709417696051!5m2!1sen!2srs" width="800" height="600" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Contact
