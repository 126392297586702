import React from 'react'
import { Link } from 'react-router-dom'
import './Breadcrumbs.scss'

function Breadcrumbs() {
  return (
    <div className='bread--main'>
        <nav className='bread'>
            <ul class="bread__ul">
                <li className='bread__list'><Link className='bread__link'to='/ticket/1'>Omnitracs Training</Link></li>
                <li className='bread__list'><Link className='bread__link'to='/ticket/2'>Snow Chains</Link></li>
            </ul>
        </nav>
      
    </div>
  )
}

export default Breadcrumbs
