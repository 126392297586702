const front = [
    {
        id: 1,
        txt: 'Omnitracs Training',
    },
    {
        id: 2,
        txt: 'Snow Chains',
    }
    /*{
        id: 3,
        txt: 'Automatic Transmision',
    },
    {
        id: 4,
        txt: 'Safety Packages',
    },
    {
        id: 5,
        txt: 'Tractor Information',
    },
    {
        id: 6,
        txt: 'Pre Trip Inspections',
    }*/
]

export default front;