import React from 'react'
import PhotoGallery from '../../components/photoGallery/PhotoGallery'
import './Gallery.scss'

function Gallery() {
  return (
    <>
        <div className='space'>
            <PhotoGallery />
        </div>
    </>
  )
}

export default Gallery
