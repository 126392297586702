const dataAcc = [
    {
        id: 1,
        headline: 'How can you guarantee weekly home time?',
        text: 'We have contract with FedEx Ground, going every week from Chino, CA to various FedEx terminals in lower 48 states and coming back to our home terminal. Every week they rely on same trucks/teams to ship their packages from our home terminal, which means if you will be dispatched on Tuesday and next Tuesday they count on you for the next dispatch assignment.'
    },
    {
        id: 2,
        headline: 'How can I make more money than regular OTR driver?',
        text: 'FedEx needs to deliver safely their packages fast to different parts of the Country. In order to provide extraordinary service for them and their customers we are running teams. We are specialized in team driving only and we take our jobs seriously. You will average 5000 miles every week and you will be paid for all the miles that truck is running, no matter are you driving or sleeping. You can go to Florida and back in 5 days and be home 2 days for your 34 break until next week assignment. Also while open market can be slow, FedEx Ground is very consistent with freight every week, so we don’t have ups and downs with work. We can keep you busy all year round.'
    },
    {
        id: 3,
        headline: 'I am a “runner” can I make more than 5000 miles a week?',
        text: 'If you are this type of driver, we have many drivers who would like to work with you in a team. Our basic dispatch guarantees you in average 5000 mi, but if you are willing to drive more and make more money, if you have time to make it back on time to your home terminal, you can make even more than 7000 miles. That would be 6 days dispatch. With team driving you should not worry and stressing about hours of service, you will always have time within your 70h week.'
    },
    {
        id: 4,
        headline: 'Is this job easier than working for owner operators or other companies on open market?',
        text: 'Yes, definitely! Your time is here most appreciated. All trailers are preloaded, it’s a drop&hook, no touch freight, terminal to terminal operation which means you will not be wasting your time on various appointments at different facilities, waiting to be loaded or unloaded, worrying about overweight and all the hard work except driving. You will be working inside FedEx Ground system, moving their packages from one FedEx terminal to another. No backing up, not navigating through city traffic since all terminals are on outskirts of big cities and we are hauling double trailers. We always encourage all drivers to apply and try this work. Many of our new employees are amazed how they didn’t know before that trucking can be so easy.'
    },
    {
        id: 5,
        headline: 'What if I recently obtained Class A license, can I be hired?',
        text: 'The best way to find out is to fill out this short application form and get in touch with our recruiter. We can hire all graduates from driving schools which are contracted with FedEx. We have many lead trainer drivers who can help you on your way to become professional truck drivers. We believe this job opportunity is also perfect for beginners, where you will gain experience from the drivers with most miles in the industry.'
    },
    {
        id: 6,
        headline: 'I don’t have experience with double trailers, can you help me with this?',
        text: 'Absolutely! Pulling double trailers is even easier than standard 53’ trailer. Even if you don’t have T endorsement on your license it’s easy to obtain it, we provide training and support during your job application process and it’s usually just 20 questions test at your local DMV.'
    }
]

export default dataAcc