const dataCc = [
    {
        id: 1,
        className: 'circle__sub',
        img: '../images/wolfy.png',
        path: 'about',
        text: 'About us'
    },
    {
        id: 2,
        className: 'circle__sub',
        img: '../images/hand.png',
        path: 'career',
        text: 'Careers'
    },
    {
        id: 3,
        className: 'circle__sub',
        img: '../images/steerer.png',
        path: 'application',
        text: 'Application'
    },
    {
        id: 4,
        className: 'circle__sub',
        img: '../images/phones.png',
        path: 'contact',
        text: 'Contact us'
    },
    {
        id: 5,
        className: 'circle__sub',
        img: '../images/img1.png',
        path: 'gallery',
        text: 'Gallery'
    },
    {
        id: 6,
        className: 'circle__sub',
        img: '../images/cloud.png',
        path: 'weather',
        text: 'Weather'
    },
    {
        id: 7,
        className: 'circle__sub',
        img: '../images/questions.png',
        path: 'faq',
        text: 'FAQ'
    },
    {
        id: 8,
        className: 'circle__sub',
        img: '../images/language1.png',
        path: 'safety',
        text: 'Safety'
    }
]

export default dataCc