import React, {useState, useEffect} from 'react';
import './Header.scss'
import { Link } from 'react-router-dom'
import MenuButton from '../button/Button';
/*import Button from '../button/Button'*/

function Header() {

    const [btn, setBtn] = useState(false)

    function btnEx() {
        setBtn(btn => !btn)
    }
    
    const [hamburger, setHamburger] = useState(true);

    function openMenu() {
        setHamburger(!hamburger)
    }

    const [isOpen, setIsOpen] = useState(false);

    function overflowHidden() {
        setIsOpen(isOpen => !isOpen)
    }

    function overflowFree() {
        setIsOpen(false)
    }
        
    useEffect(() => {
      document.body.classList.toggle('base', isOpen);
    },[isOpen])



  return (
    <>
      <div className='header'>
        <header className="wrapper">
            <div className="header__wrap">
                <div className="header__sub">
                    <Link to='/'>
                        <picture>
                            <source media="(max-width: 500px)" srcSet="../images/amaroq-logo-mob.png" />
                            <img src='../images/amaroq-logo.png' alt='logo' className='header__logo'/>
                        </picture>
                    </Link>
                </div>
                <div className="header__sub header__sub--links">
                    <nav className="nav">
                        <ul className="nav__unorder">
                            <li className="nav__item"><Link to="/" className="nav__link">Home</Link></li>
                            <li className="nav__item"><Link to="about" className="nav__link">About Us</Link></li>
                            <li className="nav__item"><Link to="faq" className="nav__link">FAQ</Link></li>
                            <li className="nav__item"><Link to="career" className="nav__link">Careers</Link></li>
                            <li className="nav__item"><Link to="application" className="nav__link">Job Application</Link></li>
                            <li className="nav__item"><Link to="contact" className="nav__link">Contact Us</Link></li>
                            <li className="nav__item"><Link to="safety" className="nav__link">Safety</Link></li>
                            <li className="nav__item"><Link to="gallery" className="nav__link">Gallery</Link></li>
                            <li className="nav__item"><Link to="weather" className="nav__link">Weather</Link></li>
                            {/*<li className="nav__item"><Link to="spanish/aboutusspanish" className="nav__link nav__link--mod"><img className='nav__flag' src='../images/es.png' alt='flag'></img></Link></li>*/}
                        </ul>
                    </nav>
                </div>
                    <MenuButton btn={btn} setBtn={setBtn} btnEx={btnEx} openMenu={openMenu} overflowHidden={overflowHidden}/>
                {hamburger ? <></> : 
                <div className="header__block">
                    <div className="header__drop">
                        <div className="header__semi">
                            <nav className="navigation">
                                <ul className="navigation__unorder">
                                    <li className="navigation__list navigation__list--cube"><Link onClick={() => {openMenu(); btnEx(); overflowFree()}} to="/" className="navigation__link">Home</Link></li>
                                    <li className="navigation__list"><Link onClick={() => {openMenu(); btnEx(); overflowFree()}} to="about" className="navigation__link">About Us</Link></li>
                                    <li className="navigation__list"><Link onClick={() => {openMenu(); btnEx(); overflowFree()}} to="faq" className="navigation__link">FAQ</Link></li>
                                    <li className="navigation__list"><Link onClick={() => {openMenu(); btnEx(); overflowFree()}} to="career" className="navigation__link">Careers</Link></li>
                                    <li className="navigation__list"><Link onClick={() => {openMenu(); btnEx(); overflowFree()}} to="application" className="navigation__link">Job Application</Link></li>
                                    <li className="navigation__list"><Link onClick={() => {openMenu(); btnEx(); overflowFree()}} to="contact" className="navigation__link">Contact Us</Link></li>
                                    <li className="navigation__list"><Link onClick={() => {openMenu(); btnEx(); overflowFree()}} to="safety" className="navigation__link">Safety</Link></li>
                                    <li className="navigation__list"><Link onClick={() => {openMenu(); btnEx(); overflowFree()}} to="gallery" className="navigation__link">Gallery</Link></li>
                                    <li className="navigation__list"><Link onClick={() => {openMenu(); btnEx(); overflowFree()}} to="weather" className="navigation__link">Weather</Link></li>
                                    {/*<li className="navigation__list"><Link onClick={() => {openMenu(); btnEx(); overflowFree()}} to="spanish" className="navigation__link"><img className='navigation__flag' src='../images/es.png' alt='flag'></img></Link></li>*/}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>}
            </div>
        </header>
      </div>
    </>
  )
}

export default Header