
import React from 'react'
import './Application.scss'

function Application() {

  const [formData, setFormData] = React.useState(
    {firstName:'',
    lastName:'',
    textArea:'',
    phoneNum: '',
    email:'',
    experience:'',
    licence: '',
    endorsement: '',
    city: ''
  }
)

  function handleChange(event) {

    /**OVO JE UKOLIKO IMA CHECKBOX U FORM-u */
    const {name, value, type, checked} = event.target
    setFormData(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
    }))
}

  return (
      <div className='home'>
        <div className='block__banner--app'>
          <div className='block__shadow'></div>
        </div>
          <div className='wrapper'>
            <form action="https://formsubmit.co/jobs@amaroqtrucking.com" method="POST" className="form">
              <h1 className='home__headline'>Company Driver Application</h1>
              <div className="error"></div>
              <label htmlFor="name" className="form__label">First Name *</label>
              <input id="name" name="name" type="text" className="form__input" placeholder="First name" required /><br/>

              <label htmlFor="lastname" className="form__label">Last Name *</label>
              <input id="lastname" name="lastname" type="text" className="form__input" placeholder="Last name" required /><br/>

              <label htmlFor="phoneNum" className="form__label">Phone Number <span className='form__letters'>(Numbers only, no characters)</span> *</label>
              <input id="phoneNum" name="phoneNum" type="text" className="form__input" placeholder="xxx-xxx-xxxx" required /><br/>

              <label htmlFor="email" className="form__label">Email Address *</label>
              <input id="email" name="email" type="email" className="form__input" placeholder="Email address" required /><br/>

              
              <label htmlFor="city" className="form__label">City of Residence *</label>
              <input id="city" name="city" type="city" className="form__input" placeholder="City" required /><br/>

              <div className='home__radio'>
                    <label className='form__lab'>Do you have Class A license? *</label><br/>
                    <input
                        type='radio'
                        id='positiveA'
                        name='licence'
                        value='positiveA'
                        onChange={handleChange}
                        checked={formData.licence === 'positiveA'}
                        className='form__spot'
                    />
                    <label htmlFor='positiveA'>Yes</label><br/>
                    <input
                        type='radio'
                        id='negativeA'
                        name='licence'
                        value='negativeA'
                        onChange={handleChange}
                        checked={formData.licence === 'negativeA'}
                        className='form__spot'
                    />
                    <label htmlFor='negativeA'>No</label><br/>
                    <input
                        type='radio'
                        id='schoolOption'
                        name='licence'
                        value='schoolOption'
                        onChange={handleChange}
                        checked={formData.licence === 'schoolOption'}
                        className='form__spot'
                    />
                    <label htmlFor='schoolOption'>If 'No', we can direct you to a qualified school after which you can immediately get a job.</label><br/>

                    <div className='home__checkbox'>
                      <h4 className='home__head'>How many years of tractor-trailer experience do you have? *</h4>
                      <input 
                          type='radio'
                          id='none'
                          name='experience'
                          value='none'
                          checked={formData.experience === 'none'}
                          onChange={handleChange}
                          className='form__spot'
                      />
                      <label htmlFor='none'>None</label><br/>
                      <input 
                          type='radio'
                          id='oneYear'
                          name='experience'
                          value='oneYear'
                          checked={formData.experience === 'oneYear'}
                          onChange={handleChange}
                          className='form__spot'
                      />
                      <label htmlFor='oneYear'>Up to 1 year</label><br/>
                      <input 
                          type='radio'
                          id='threeYears'
                          name='experience'
                          value='threeYears'
                          checked={formData.experience === 'threeYears'}
                          onChange={handleChange}
                          className='form__spot'
                      />
                      <label htmlFor='threeYears'>1 - 3 years</label><br/>

                      <input 
                          type='radio'
                          id='fiveYears'
                          name='experience'
                          value='fiveYears'
                          checked={formData.experience === 'fiveYears'}
                          onChange={handleChange}
                          className='form__spot'
                      />
                      <label htmlFor='fiveYears'>More then 5 years</label><br/>
                    </div>

                    <label className='form__lab'>Do you have T (double/triple) endorsement? *</label><br/>
                    <input
                        type='radio'
                        id='yesIdo'
                        name='endorsement'
                        value='yesIdo'
                        onChange={handleChange}
                        checked={formData.endorsement === 'yesIdo'}
                        className='form__spot'
                    />
                    <label htmlFor='state'>Yes</label><br/>
                    <input
                        type='radio'
                        id='noIdont'
                        name='endorsement'
                        value='noIdont'
                        onChange={handleChange}
                        checked={formData.endorsement === 'noIdont'}
                        className='form__spot'
                    />
                    <label htmlFor='other'>No</label><br/>
                    <input
                        type='radio'
                        id='obtain'
                        name='endorsement'
                        value='obtain'
                        onChange={handleChange}
                        checked={formData.endorsement === 'obtain'}
                        className='form__spot'
                    />
                    <label htmlFor='other'>No, but I wish to obtain T endorsement with your support during job application process.</label><br/>
              </div>
              <div className="row">
              {/*<div className="col">
                  <div className="tab">
                      <input className='accordion__input' type="checkbox" id='test' />
                      <label className="tab-label" htmlFor='test'>Optional: Refer a fellow trucker for this job opportunity and earn $500 referral bonus</label>
                      <div className="tab-content">
                        <div className='footer__top'>
                          <p className='footer__para'>Make money while applying with us! For every qualified driver we choose to hire, you will get $500 referral bonus.</p>
                        </div>
                        <label htmlFor="optionalName" className="form__label">First Name *</label>
                        <input id="optionalName" name="optionalName" type="text" className="form__input" placeholder="First name" /><br/>

                        <label htmlFor="optionalLastname" className="form__label">Last Name *</label>
                        <input id="optionalLastname" name="optionalLastname" type="text" className="form__input" placeholder="Last name" /><br/>

                        <label htmlFor="optionalPhoneNum" className="form__label">Phone Number <span className='form__letters'>(Numbers only, no characters)</span> *</label>
                        <input id="optionalPhoneNum" name="optionalPhoneNum" type="text" className="form__input" placeholder="xxx-xxx-xxxx" /><br/>
                      </div>
                  </div>
    </div>*/}
          </div>
              <button type="submit" value="submit" className="form__btn-2"><span className="form__span">Submit</span></button>
            </form>
          </div>
      </div>
  )
}

export default Application
