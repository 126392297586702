import React from 'react'
import { Link } from 'react-router-dom'
import './Info.scss'
import Accordion from '../accordion/Accordion'

function Info({scrollToTop}) {
  return (
    <div className='accordion'>
        <div className='wrapper'>
          <div className='accordion__wrap'>
            <div className='accorrdion__sub accordion__sub--width'>
              <h1 className='accordion__header'>Unique Truck Driving Jobs on the Market – Make more money than regular OTR Driver and still be home every week guaranteed!</h1>
              <p className='accordion__paragraph'>While regular OTR jobs require drivers to stay couple of weeks over the road far from their families in order to make money, our relationship with FedEx Ground guarantees no matter what, you will be home weekly for every 34h break.</p>
              <p className='accordion__para'>Frequently asked questions</p>
              <Accordion />
              <p className='accordion__paragraphs accordion__paragraphs--mod'>If you read everything so far, we already love you and we are excited to meet you in person and answer many more questions you may have.</p>
              <p className='accordion__paragraphs'>Please introduce yourself by filling out this short application form and we will get back to you quickly</p>
              <Link onClick={scrollToTop} to='/career'><button className='btn btn--mod'>Read More</button></Link>
            </div>
            <div className='accorrdion__sub accordion__sub--img'>
              <img loading='lazy' src='./images/semi-truck.jpg' alt='truck' className='accordion__image1'/>
              <img loading='lazy' src='./images/road.jpg' alt='road' className='accordion__image2'/>
              <img loading='lazy' src='./images/cabin.png' alt='truck' className='accordion__image3'/>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Info
