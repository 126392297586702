import React from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom'

function Footer({scrollToTop}) {
  return (
    <>
    <div className='footer'>
      <div className='wrapper'>
        <div className='footer__wrap'>
          <div className='footer__sub'>
            <Link onClick={scrollToTop} to='/'>
              <picture>
                  <source media="(max-width: 500px)" srcSet="../images/amaroq-logo-mob.png" />
                  <img src='../images/amaroq-logo.png' alt='logo' className='header__logo header__logo--footer'/>
              </picture>
            </Link>
          </div>
          <div className='footer__sub'>
            <h3 className='footer__header'>Contact Us</h3>
            <ul className='footer__unorder'>
              <li className='footer__item'><p className='footer__paragraph'><span className='icon-phone'></span>(562) 225 - 0126</p></li>
              <li className='footer__item'><p className='footer__paragraph'><span className='icon-mail-alt'></span> amaroqtrucking@gmail.com</p></li>
            </ul>
          </div>
          <div className='footer__sub'>
            <h3 className='footer__header'>Location</h3>
            <ul className='footer__unorder'>
              <li className='footer__item'><p className='footer__paragraph'><span className='icon-location'></span>5318 E 2nd St #344 Long Beach, CA 90803</p></li>
              <li className='footer__item'><p className='footer__paragraph'><span className='icon-location'></span>Home Terminals: Chino, CA / Santa Fe Springs, CA</p></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className='footer__bottom'>
      <div className='wrapper'>
        <div className='footer__wraps'>
          <p className='footer__paragraph'>© Copyright by milsec</p>
          <p className='footer__paragraph'>Website designed by Laygar</p>
        </div>
      </div>
    </div>
    </>
  )
}

export default Footer
