import React from 'react'
import './Weather.scss'

function Weather() {
  return (
    <div className='space'>
        <div className='wrapper'>

        </div>
        <div className='wrapper'>
            <iframe width="100%" height="850" title='radar' src="https://embed.windy.com/embed2.html?lat=35.796&lon=-118.757&detailLat=36.197&detailLon=-118.756&width=650&height=450&zoom=8&level=surface&overlay=temp&product=ecmwf&menu=&message=&marker=&calendar=24&pressure=true&type=map&location=coordinates&detail=true&metricWind=mph&metricTemp=%C2%B0F&radarRange=-1" frameborder="0"></iframe>
        </div>
      
    </div>
  )
}

export default Weather
