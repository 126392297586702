import React from 'react'
import './Social.scss'
//import 'semantic-ui-css/semantic.min.css'
import { Container, Segment } from 'semantic-ui-react'
import {FacebookShareButton, TelegramShareButton, ViberShareButton, WhatsappShareButton} from 'react-share'
import {FacebookIcon, TelegramIcon, ViberIcon, WhatsappIcon} from 'react-share'
import UpButton from '../upButton/UpButton'

function Social() {
  return (
    <>
        <div className='share'>
            <Container>
                <Segment>
                    <FacebookShareButton url='https://www.amaroqtrucking.com'>
                        <FacebookIcon lightingColor='white' round={true} width='35' height='35'></FacebookIcon>
                    </FacebookShareButton>
                </Segment>
            </Container>

            <Container>
                <Segment>
                    <TelegramShareButton url='https://www.amaroqtrucking.com'>
                        <TelegramIcon lightingColor='white' round={true} width='35' height='35'></TelegramIcon>
                    </TelegramShareButton>
                </Segment>
            </Container>

            <Container>
                <Segment>
                    <ViberShareButton url='https://www.amaroqtrucking.com'>
                        <ViberIcon lightingColor='white' round={true} width='35' height='35'></ViberIcon>
                    </ViberShareButton>
                </Segment>
            </Container>

        <Container>
            <Segment>
                <WhatsappShareButton url='https://www.amaroqtrucking.com' title='Sevu enterijer'>
                    <WhatsappIcon lightingColor='white' round={true} width='35' height='35'></WhatsappIcon>
                </WhatsappShareButton>
            </Segment>
        </Container>
        <UpButton />
        </div>
    </>
  )
}

export default Social
