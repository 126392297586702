import React from 'react'
import Info from '../../components/info/Info'
import './Faq.scss'

function Faq({scrollToTop}) {
  return (
    <>
      <div className='info'>
        <div className='block__banner--careers'>
          <div className='block__shadow'></div>
        </div>
        <Info scrollToTop={scrollToTop} />
      </div>
    </>
  )
}

export default Faq
