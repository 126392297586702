import './Button.scss'
import React from 'react';

function MenuButton({btn, btnEx, openMenu, overflowHidden}) {

  let toggleClass = btn ? 'menu-button active' : 'menu-button';

  return (
    <>
        <button onClick={() => {btnEx(); openMenu(); overflowHidden();}} className={toggleClass}>
        <span className='bar'></span>
      </button>
    </>
  )
}

export default MenuButton
