import React from 'react'
import { Link } from 'react-router-dom'
import './About.scss'

function About({scrollToTop}) {
  return (
    <div className='block'>
      <div className='block__banner'>
        <div className='block__shadow'></div>
      </div>
      <div className='wrapper'>
        <div className='block__main'>
          <div className='block__sub'>
            <h1 className='block__header'>About Us</h1>
            <p className='block__text'>When we embarked on the journey to establish our small trucking company, we understood that the name would not only serve as an identity but also reflect our collective values and aspirations. After thorough deliberation, we chose &quot;Amaroq Trucking&quot; for its profound connection to the Amaroq Wolf.</p>
            <p className='block__text'>In Inuit mythology, the Amaroq Wolf is revered as a symbol of strength, courage, and resourcefulness. These are qualities that we believe are fundamental in the trucking industry, where challenges and obstacles are part of the journey. By naming our company after this majestic creature, we aim to embody its spirit as we traverse the highways and byways of our nation.</p>
            <p className='block__text'>However, the significance of the name extends beyond symbolism. Just as the Amaroq Wolf is a master of its environment, we strive to be masters of our craft, providing top- notch transportation services with precision and expertise. Our commitment to excellence and reliability resonates in every facet of our operations, from our dedicated team of drivers to our state-of-the-art fleet of trucks.</p>
            <p className='block__text'>In choosing &quot;Amaroq Trucking,&quot; we sought to convey a sense of pride, integrity, and leadership. Our goal is not simply to transport goods from point A to point B, but to do so with the utmost care and professionalism, earning the trust and respect of our clients every step of the way.</p>
          </div>
          <div className='block__sub block__sub--mod'>
            <div>
            <h2 className='block__head'>We build trust for years ahead</h2>
            <p className='block__para'>Let us be your trusted employer!</p>
            <Link onClick={scrollToTop} className='btn btn--mod' to='/application'>Apply Now</Link>
            </div>
          </div>
        </div>
        <div className='block__main'>
          <img loading='lazy' src='../images/highway.jpg' alt='highway' className='block__img'/>
          <div className='block__pad'>
            <h2 className='block__heading'>Why Amaroq Trucking?</h2>
            <p className='block__text block__text--mod'><b>Family-Friendly Schedule</b>: We recognize the value of spending time with loved ones. Despite being in linehaul over-the-road trucking, our drivers are guaranteed to be home weekly, fostering a healthy work-life balance.</p>
            <p className='block__text block__text--mod'><b>Freedom from Ownership Burdens</b>: Unlike owner operators burdened by the complexities and costs of ownership, our drivers can focus solely on their driving duties. We handle all aspects of truck maintenance and operational logistics, freeing our drivers from these concerns.</p>
            <p className='block__text block__text--mod'><b>Efficient Operations</b>: Our drop &amp; hook, terminal-to-terminal operation guarantees that our drivers won&#39;t waste time at various shippers and receivers. As we exclusively operate within the FedEx Ground system, this streamlined process ensures maximum efficiency, productivity, and convenience for our drivers.</p>
            <p className='block__text block__text--mod'><b>Personalized Attention</b>: At Amaroq Trucking, every employee is valued and receives personalized attention. From safety protocols to dispatch services, we prioritize the well-being and satisfaction of our team members.</p>
            <p className='block__text block__text--mod'><b>Join Us Today</b>: If you&#39;re passionate about driving and always aim for more miles, then Amaroq Trucking is the perfect place for you. At Amaroq, we recognize and value your dedication behind the wheel. Experience the difference with us. Contact us now to learn more about our operations and discover the advantages of driving with Amaroq.</p>
          </div>
        </div>
        <div className='block__fedex'>
          <h2 className='block__headline'>Contracted Service Provider for FedEx Ground</h2>
        </div>
      </div>
    </div>
  )
}

export default About
