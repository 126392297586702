import React, {useState} from 'react';
import './App.scss';
import {HashRouter as Router, Routes, Route} from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Social from './components/social/Social';
import Faq from './pages/faq/Faq';
import Application from './pages/application/Application';
import Gallery from './pages/gallery/Gallery';
import Weather from './pages/weather/Weather';
import About from './pages/about/About';
import Career from './pages/career/Career';
import Safety from './pages/safety/Safety';
import Ticket from './components/ticket/Ticket'

function App() {

  const [ticket, setTicket] = useState('')

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Router>
        <Header />
        <Social />
        <Routes>
          <Route path='/' element={ <Home scrollToTop={scrollToTop} /> } />
          <Route path='faq' element={ <Faq scrollToTop={scrollToTop} /> } />
          <Route path='application' element={ <Application /> } />
          <Route path='contact' element={ <Contact /> } />
          <Route path='career' element={ <Career /> }/>
          <Route path='about' element={ <About scrollToTop={scrollToTop} /> } />
          <Route path='safety' element={ <Safety /> } />
          <Route path='gallery' element={ <Gallery /> } />
          <Route path='weather' element={ <Weather /> } />
          <Route path='/ticket/:id' element={ <Ticket ticket={ticket} /> }/>
        </Routes>
        <Footer scrollToTop={scrollToTop} />
      </Router>
    </>
  );
}

export default App;
