import React from 'react'
import './Career.scss'
import Sheet from '../../components/sheet/Sheet'
import {Link} from 'react-router-dom'

function Career() {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className='token'>
      <div className='block__banner block__banner--contact'>
        <div className='block__shadow'></div>
      </div>
      <Sheet />
      <div className='btn__width'>
        <Link onClick={scrollToTop} className='btn btn--mod' to='/application'>Apply Now</Link>
      </div>
    </div>
  )
}

export default Career
