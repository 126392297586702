import React from 'react'
import './Ticket.scss'
import box from './homeData'
import { Link, useParams } from 'react-router-dom'
import Breadcrumbs from '../breadcrumbs/Breadcrumbs'

function Ticket({ticket}) {

  const { id } = useParams()
  const data = box.filter((item) => item.id == id);
  console.log(data)
  return (
    <>
      <div key={id}>
        <div className='containers'>
          <div className='wrapper'>
            <Breadcrumbs />
            <div className='containers__flex'>
              <h3 className='containers__head'>{data[0].headline}</h3>
              <span className='containers__box'>{data[0].span1}</span>
              <span className='containers__box'>{data[0].span2}</span>
              <span className='containers__box'>{data[0].span3}</span>
              <span className='containers__box'>{data[0].span4}</span>
              <span className='containers__box'>{data[0].span5}</span>
              <span className='containers__box'>{data[0].span6}</span>
              <span className='containers__box'>{data[0].span7}</span>
              <span className='containers__box'>{data[0].span8}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Ticket
