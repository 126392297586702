import React from 'react'
import { Link } from 'react-router-dom'
import './Circle.scss'
import dataCircle from './dataCircle'


function Circle({scrollToTop}) {
  
  return dataCircle.map ((index) => (
    <div key={index.id}>
        <Link onClick={scrollToTop} className='circle__link' to={index.path}>
            <div className='circle__sub'>
                <img loading='lazy' className='circle__image' src={index.img} alt='logo' />
            </div>
            <p className='circle__text'>{index.text}</p>
        </Link>
    </div>
  ))
}

export default Circle
