const box = [
    {
        id: 1,
        headline: 'Omnitracs Training',
        span1: <iframe width="100%" height="315" src="https://www.youtube.com/embed/EntsCTx8JFE?si=D8TfOe9cnKcBHNUi" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span2: <iframe width="100%" height="315" src="https://www.youtube.com/embed/pYf4IA5YQIU?si=_MDXUvSaegFURy2P" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span3: <iframe width="100%" height="315" src="https://www.youtube.com/embed/HclUKFEA5u0?si=56ki8q2YOSsZUtvU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span4: <iframe width="100%" height="315" src="https://www.youtube.com/embed/SFKMiSZfA5c?si=osivKaNtyBqtaTpC" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span5: <iframe width="100%" height="315" src="https://www.youtube.com/embed/54C3l7OsLbE?si=xKIStSOI42m0K1ER" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span6: <iframe width="100%" height="315" src="https://www.youtube.com/embed/pc5wOG5BXXM?si=OLC8YYlEgaqiF_JK" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span7: <iframe width="100%" height="315" src="https://www.youtube.com/embed/5ABkx8gPVPU?si=bLCUo2UGJd7cirHO" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span8: <iframe width="100%" height="315" src="https://www.youtube.com/embed/qeiFWOwk5jU?si=5RZGsx57S8lVhqBI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

    },
    {
        id: 2,
        headline: 'Snow Chains',
        span1: <iframe width="100%" height="315" src="https://www.youtube.com/embed/bUcEX0yQolc?si=WkUKi3dkRqoiuDIb" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span2: <iframe width="100%" height="315" src="https://www.youtube.com/embed/R5PgL95jzVg?si=HgpsAJssRsf9MRgt" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span3: <iframe width="100%" height="315" src="https://www.youtube.com/embed/R5PgL95jzVg?si=k_M1DrqSfKqZ6XhY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    },
    {
        id: 3,
        headline: 'Automatic Transmision',
        span1: <iframe width="100%" height="315" src="https://www.youtube.com/embed/Oj751QQJWuk?si=7siv9kg6EAc6inzr" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span2: <iframe width="100%" height="315" src="https://www.youtube.com/embed/Pf4iSpSC4hs?si=IIP2-s1B9cxdAa4K" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span3: <iframe width="100%" height="315" src="https://www.youtube.com/embed/giy9DMr6LIo?si=EJCOiQNDHbsVcKLU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> 
    },
    {
        id: 4,
        headline: 'Safety Packages',
        span1: <iframe width="100%" height="315" src="https://www.youtube.com/embed/3xaSrVY-pEk?si=UR2r_8I_WjjMXMc5" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span2: <iframe width="100%" height="315" src="https://www.youtube.com/embed/VQ1DUQfBepc?si=MwAllLVOZ6KwSYFo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span3: <iframe width="100%" height="315" src="https://www.youtube.com/embed/wBAfi-iWSIo?si=7m0Xm8EPgDM0ChpK" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span4: <iframe width="100%" height="315" src="https://www.youtube.com/embed/wBAfi-iWSIo?si=FXQEFuarIHk8KRpP" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    },
    {
        id: 5,
        headline: 'Tractor Information',
        span1: <iframe width="100%" height="315" src="https://www.youtube.com/embed/oQ8J6a2Yi8M?si=8tyvvbFNlthuLZZe" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span2: <iframe width="100%" height="315" src="https://www.youtube.com/embed/NQboHgah9dQ?si=hJ5HazsMp4mamtrf" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
    },
    {
        id: 6,
        headline: 'Pre Trip Inspections',
        span1: <iframe width="100%" height="315" src="https://www.youtube.com/embed/2rJite5wSyU?si=LiW9-OBGzMk8E4qr" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span2: <iframe width="100%" height="315" src="https://www.youtube.com/embed/OcgocE4leCI?si=xONk6KZtftu0C0on" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span3: <iframe width="100%" height="315" src="https://www.youtube.com/embed/JbLt8CxyYOo?si=33kMKuJC1qW6hV0V" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span4: <iframe width="100%" height="315" src="https://www.youtube.com/embed/y7a5vTlHJkI?si=hejcQ2Mlye81-Ydf" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>,
        span5: <iframe width="100%" height="315" src="https://www.youtube.com/embed/VPJ1biinnx8?si=8tmJJGxZ6efJE_0T" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> 
    }
]

export default box;