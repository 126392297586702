import React from 'react'
import './Accordion.scss'
import dataAcc from './dataAccordion'

function Accordion() {
    return dataAcc.map((index) => (
        <div className="row" key={index.id}>
            <div className="col">
                <div className="tab">
                    <input className='accordion__input' type="checkbox" id={index.id} />
                    <label className="tab-label" htmlFor={index.id}>{index.headline}</label>
                    <div className="tab-content">{index.text}</div>
                </div>
            </div>
        </div>
    ))
}

export default Accordion
