import React, {useEffect, useState} from 'react'
import './UpButton.scss'

function UpButton() {

    const [backToTop, setBackToTop] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if(window.scrollY > 100) {
                setBackToTop(true)
            } else {
                setBackToTop(false)
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
  return (
    <>
      {backToTop && (<button className='up__button' onClick={scrollUp}><div className="up__icon"></div></button>)}
    </>
  )
}

export default UpButton
