const sheetcards = [
    {
        id: 1,
        headline: 'Full Time Position',
        cName: 'sheet__box',
        para1: '- Dispatched for 5 to 6 days per week with 2 days at home for a reset each week.',
        para2: '- Team position covering all 48 states.',
        para3: '- Terminal to terminal operation, drop and hook, no-touch operation.'
    },
    {
        id: 2,
        headline: 'Part Time or On-Call Position (A good way to transition to a full-time position)',
        cName: 'sheet__box',
        para1: '- Assigned for 2 to 5 days per week.',
        para2: '- This team role spans all 48 states.',
        para3: '- Part-time/On Call drivers have the option to work weekly, bi-weekly, or whenever they are available.',
        para4: '- No long-term commitment is required for part-time roles.'
    },
    {
        id: 3,
        headline: 'Details',
        cName: 'sheet__box',
        para1: '- OTR – All 48 States',
        para2: '- Up to 5 days out, 2 days home.',
        para3: '- Weekly Home Time',
        para4: '- Terminal to Terminal',
        para5: '- Drop and Hook',
        para6: '- Team driving',
        para7: '- Earn $75,000 your first year',
        para8: '- Bonus Opportunities'
    },
    {
        id: 4,
        headline: 'Requirements',
        cName: 'sheet__box',
        para1: '- Valid California CDL Class A License',
        para2: '- 1yr CDL Class A experience',
        para3: '- <1yr Experience with approved School',
        para4: '- Current Medical Card',
        para5: '- No DUI within 5 years',
        para6: '- Must pass background check',
        para7: '- Max 2 moving violations within 3 yrs',
        para8: '- Max 1 accident within 3 yrs',
        para9: '- T Endorsement(can be obtained during the application process)'
    }
]

export default sheetcards;