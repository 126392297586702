import React from 'react'
import { Link } from 'react-router-dom'
import Circle from '../../components/circle/Circle'
import './Home.scss'

function Home({scrollToTop}) {

  return (
    <>
      <section>
        <div className='background'>
          <div className='background__shadow'></div>
          <video className='background__video' autoPlay muted loop>
            <source src='../images/truck-driving.mp4' type='video/mp4' />
          </video>
          <div className='wrapper'>
            <div className='background__main'>
              <div className='background__sub'>
                <div className='spot'>
                  <h1 className='spot__header'>We've built a community where team players thrive, earning excellent pay while enjoying the comforts of linehaul driving</h1>
                  <p className='spot__text'>Our focus is on creating better dispatch opportunities, enhancing skills, and providing flexibility in work schedules. We prioritize safety, ensuring that all our drivers conduct themselves professionally during their assignments.</p>
                  <p className='spot__text spot__text--mod'>Recognizing the importance of work-life balance, we respect our employees' personal time and never impose dispatches. By emphasizing safety and simplifying dispatch assignments, Amaroq Trucking fosters a motivated and well-prepared workforce ready to tackle any challenge.</p>
                  <Link className='btn btn--banner' to='/application'>Apply Now</Link>
                </div>
              </div>
              <div className='background__sub'></div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='shape'>
          <div className='shape__sub'>
            <h1 className='shape__headline'>Why Amaroq Trucking?</h1>
            <p className='shape__para'>At Amaroq Trucking, we prioritize consistency and reliability in our operations, ensuring our drivers receive the support they need to thrive. Here is why we stand out:</p>
            <p className='shape__para'><b>Consistent Work</b>: We understand the importance of steady employment for our drivers. That's why we go the extra mile to secure consistent work, allowing our team to focus on what they do best – driving.</p>
            <p className='shape__para'><b>Stability and Security</b>: Our drivers enjoy the peace of mind that comes with a reliable paycheck and job security, even though this is at-will employment. We aim to eliminate the need for our drivers to seek additional employment opportunities by providing a stable and fulfilling work environment.</p>
            <Link  onClick={scrollToTop} className='btn btn--mod' to='about'>Read more</Link>
          </div>
        </div>
      </section>
      <section>
        <div className='wrapper'>
          <div className='box'>
            <div className='box__sub'>
              <div className='card'>
                <h1 className='card__header'>About Us</h1>
                <p className='card__para'>In choosing &quot;Amaroq Trucking,&quot; we sought to convey a sense of pride, integrity, and leadership. Our goal is not simply to transport goods from point A to point B, but to do so with the utmost care and professionalism, earning the trust and respect of our clients every step of the way.</p>
                <div className='card__cube'></div>
                <Link  onClick={scrollToTop} className='btn' to='about'>Read More</Link>
              </div>
            </div>
            <div className='box__sub box__sub--mod'></div>
          </div>
        </div>
      </section>
      <section className='circle--line'>
        <div className='wrapper'>
          <div className='circle'>
            <Circle scrollToTop={scrollToTop} />
          </div>
          <p className='circle__moto'>Where Reliability Meets the Road - <span className='circle__span'>Amaroq Trucking</span></p>
        </div>
      </section>
    </>
  )
}

export default Home
