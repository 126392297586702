import React from 'react'
import sheetData from './sheetData'
import './Sheet.scss'

function Sheet() {
  return sheetData.map ( (item) => (
    <div className='sheet' key={item.id}>
        <div className='wrappers'>
            <div className={item.cName}>
                <div className='sheet__sub'>
                </div>
                <div className='sheet__sub sheet__sub--mod'>
                    <p className='sheet__headline'>{item.headline}</p>
                    <p className='sheet__text'>{item.text}</p>
                    <nav>
                        <ul className='sheet__list'>
                            <li className='sheet__item'><p className='sheet__para'>{item.para1}</p></li>
                            <li className='sheet__item'><p className='sheet__para'>{item.para2}</p></li>
                            <li className='sheet__item'><p className='sheet__para'>{item.para3}</p></li>
                            <li className='sheet__item'><p className='sheet__para'>{item.para4}</p></li>
                            <li className='sheet__item'><p className='sheet__para'>{item.para5}</p></li>
                            <li className='sheet__item'><p className='sheet__para'>{item.para6}</p></li>
                            <li className='sheet__item'><p className='sheet__para'>{item.para7}</p></li>
                            <li className='sheet__item'><p className='sheet__para'>{item.para8}</p></li>
                            <li className='sheet__item'><p className='sheet__para'>{item.para9}</p></li>
                            <li className='sheet__item'><p className='sheet__para'>{item.para10}</p></li>
                            <li className='sheet__item'><p className='sheet__para'>{item.para11}</p></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
  ))
}

export default Sheet
